import React, { useEffect, useState } from "react";
import logoUbuntuStore from "../../assets/img/novo-logo-ubuntu.png";
import "./renderizadorDeImagem.css";

const RenderizadorDeImagem = (props ) => {
  const [imagem, setImagem] = useState();
useEffect(()=>{
    setImagem(props.imagem)
})
  if (imagem != null) {
    return (
      <img  width={props.width}
      height={props.height}
        src={`data:image/jpeg;base64,${props.imagem}`}
        
        
      />
    );
  } else {
    return (
      <img src={logoUbuntuStore} width={props.width} height={props.height} />
    );
  }
};



export default RenderizadorDeImagem;
