import { Stack, Typography } from "@mui/material";
import "../assets/style/primeira-secao.modules.css";
import React from "react";
import { BotaoBaixeApp } from "./BotaoBaixeApp";

function PrimeiraSecao() {

}

export default PrimeiraSecao;
