import { Button, Typography } from '@mui/material';
import teste from '../assets/img/teste.png';
import "../assets/style/botao-baixe-app.modules.css"

export function BotaoBaixeApp(){
    return (
        <>
            <Button size='large' variant="contained"  href='https://play.google.com/store/apps/details?id=br.com.ubuntu' >
                <Typography 
                variant = "h4"
                 textTransform={'none'}>Baixe o app na Play Store</Typography>
            </Button>
        </>
    );
}
