import HeaderEstabelecimento from "../../../cabecalho/estabelecimento/HeaderEstabelecimento";
import BarraLateral from "../../../barra-lateral/BarraLateral";
import Footer from "../../../Footer";
import "./editar-item-cardapio.modules.css";
import axios, { AxiosHeaders } from "axios";
import { useState, useEffect } from "react";
import { ambiente } from "../../../../propriedades";
import { devIp } from "../../../../propriedades";
import { prodIp } from "../../../../propriedades";
import { propiedadesDoTema } from "../../../../utils/tema";
import { useNavigate } from "react-router-dom";
import FormCriarEditarProduto from "./FormCriarEditarProduto";
import {
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from "@mui/material";
import "../../../../utils/StringUtil"
import { formatarPreco, formatarPrecoProduto } from "../../../../utils/StringUtil";

function EditarItemCardapio(props) {
  const navigate = useNavigate();
  const id = localStorage.getItem("produto");
  const token = localStorage.getItem("token");
  const estabelecimentoId = useState(localStorage.getItem("estabelecimentoId"));
  const [trocouImagem, setTrocouImagem] = useState(false);
  const [campoImagem, setCampoImagem] = useState(null);
  const tema = createTheme(propiedadesDoTema);
  const [categorias, setCategorias] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState("");
  const [confirmacaoDelecao, setConfirmacaoDelecao] = useState(false);
  const [nomeProduto, setNomeProduto] = useState("");
  const [precoProduto, setPrecoProduto] = useState("");
  const [tempoDePreparo, setTempoDePreparo] = useState("");
  const [calorias, setCalorias] = useState("");
  const [disponivel, setDisponivel] = useState(true);
  const [contemGluten, setContemGluten] = useState(false);
  const [vegetariano, setVegetariano] = useState(false);
  const [vegano, setVegano] = useState(false);
  const [descricao, setDescricao] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const setImagem = (e) => {
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };

  useEffect(() => {
    obterTela();
  }, []);

  let ip = "";
  if (ambiente === "dev") {
    ip = devIp;
  }

  if (ambiente === "prod") {
    ip = prodIp;
  }

  const obterTela = async () => {
    const resposta = await axios.post(
      ip.toString() + "/estabelecimento/telas/editarproduto",
      {
        idDoProduto: id,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    let precoRecebido = "R$"+ resposta.data.preco + ",00"


    setCategoriaSelecionada(resposta.data.categoriaDoProduto.categoriaEnum);
    setCategorias(resposta.data.categorias);
    setNomeProduto(resposta.data.nome);
    setPrecoProduto(precoRecebido);
    setTempoDePreparo(resposta.data.tempoDePreparo);
    setDescricao(resposta.data.descricao);
    setContemGluten(resposta.data.contemGluten);
    setVegetariano(resposta.data.vegetariano);
    setVegano(resposta.data.vegano);
    setCalorias(resposta.data.calorias);
    setDisponivel(resposta.data.disponivel);
  };
  
  const deletarProduto = async () => {
    const resposta = await axios.post(
      ip.toString() + "/produto/deletar",
      {
        idProduto: id,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (resposta.status == 200) {
      navigate("/estabelecimentos/cardapio");
    }
  };

  const definirImagemProdutoEditado = (event) => {
    try {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        let url = event.target.result;
        console.log(url);

        let image = document.createElement("img");
        image.src = url;

        image.onload = () => {
          const larguraDesejada = 200;
          const alturaDesejada = 200;

          let canvas = document.createElement("canvas");
          canvas.width = larguraDesejada;
          canvas.height = alturaDesejada;

          let context = canvas.getContext("2d");

          context.drawImage(image, 0, 0, larguraDesejada, alturaDesejada);
          canvas.toBlob(
            async (blob) => {
              const arrayBuffer = await blob.arrayBuffer();
              const byteArray = new Uint8Array(arrayBuffer);
              const base64Data = btoa(
                String.fromCharCode.apply(null, byteArray)
              );
              setCampoImagem(base64Data);
              setTrocouImagem(true);
            },
            "image/jpeg",
            0.9
          );
        };
      };
    } catch (error) {
      console.error("Erro ao processar a imagem:", error);
    }
  };
  const enviarProduto = async () => {
    
    let precoEnviado=precoProduto
    precoEnviado= precoEnviado.split(",")[0]
    precoEnviado= precoEnviado.replace("R$", "")

    const resposta = await axios.post(
      ip.toString() + "/produto/editar",
      {
        idDoProduto: id,
        nome: nomeProduto,
        preco: precoEnviado,
        imagem: campoImagem,
        trocouImagem: trocouImagem,
        descricao: descricao,
        contemGluten: contemGluten,
        vegetariano: vegetariano,
        vegano: vegano,
        calorias: calorias,
        tempoDePreparo: tempoDePreparo,
        disponivel: disponivel,
        categoria: categoriaSelecionada,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  };

  const dialogoConfirmacaoDelecao = async () => {
    setConfirmacaoDelecao(true);
  };

  return (
    <ThemeProvider theme={tema}>
      <HeaderEstabelecimento logo={true} />
      <div className="homeEstabelecimento">
        <BarraLateral />
        <main className="formulario-cadastro">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              enviarProduto();
              navigate("/estabelecimentos/cardapio");
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"end"}
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setConfirmacaoDelecao(true);
                }}
              >
                {" "}
                <Typography textTransform={"none"}>Remover</Typography>{" "}
              </Button>
            </Stack>
            <Stack direction={"column"}>
              <TextField
                type="file"
                variant="outlined"
                width="50%"
                margin="normal"
                name="imagem"
                accept="image/*"
                onChange={(e) => {
                  definirImagemProdutoEditado(e);
                }}
              />
              <TextField
                label="Nome"
                variant="outlined"
                width="50%"
                margin="normal"
                name="nome"
                value={nomeProduto}
                onChange={(e) => {
                  setNomeProduto(e.target.value);
                }}
              />
              <TextField
                select
                fullWidth
                label="Selecione a categoria"
                variant="outlined"
                margin="normal"
                value={categoriaSelecionada}
                onChange={(e) => {
                  setCategoriaSelecionada(e.target.value);
                }}
              >
                {categorias.map((opcao) => (
                  <MenuItem color="black" value={opcao.categoriaEnum}>
                    {opcao.nome}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Preço"
                variant="outlined"
                type="text"
                width="50%"
                margin="normal"
                name="preco"
                value={precoProduto}
                onChange={(e) => {
                  formatarPreco(e.target.value,setPrecoProduto);
                }}
              />
              <TextField
                label="Tempo de Preparo"
                variant="outlined"
                fullWidth
                margin="normal"
                name="tempoDePreparo"
                value={tempoDePreparo}
                onChange={(e) => {
                  setTempoDePreparo(e.target.value);
                }}
              />
              <TextField
                label="Calorias"
                variant="outlined"
                fullWidth
                margin="normal"
                name="calorias"
                value={calorias}
                onChange={(e) => {
                  setCalorias(e.target.value);
                }}
              />
              <TextField
                label="Descrição"
                variant="outlined"
                fullWidth
                margin="normal"
                name="descricao"
                value={descricao}
                onChange={(e) => {
                  setDescricao(e.target.value);
                }}
              />
              <Grid container>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="contemGluten"
                        checked={contemGluten}
                        onChange={(e) => {
                          setContemGluten(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <span style={{ color: "black" }}>Contém Glúten</span>
                    }
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="vegetariano"
                        checked={vegetariano}
                        onChange={(e) => {
                          setVegetariano(e.target.checked);
                        }}
                      />
                    }
                    label={<span style={{ color: "black" }}>Vegetariano</span>}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="vegano"
                        checked={vegano}
                        onChange={(e) => setVegano(e.target.checked)}
                      />
                    }
                    label={<span style={{ color: "black" }}>Vegano</span>}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="disponivel"
                        checked={disponivel}
                        onChange={(e) => {
                          setDisponivel(e.target.checked);
                        }}
                      />
                    }
                    label={<span style={{ color: "black" }}>Disponível</span>}
                  />
                </Grid>
              </Grid>
            </Stack>

            <Stack alignItems={"center"} justifyContent="end" direction={"row"}>
              <Button
                onClick={() => {
                  navigate("/estabelecimentos/cardapio");
                }}
                color="primary"
              >
                <Typography textTransform={"none"}>Cancelar</Typography>
              </Button>
              <Button type="submit" variant="contained" color="primary">
                <Typography textTransform={"none"}>Salvar</Typography>
              </Button>
            </Stack>
            <Dialog
              open={confirmacaoDelecao}
              onClose={() => {
                setConfirmacaoDelecao(false);
              }}
            >
              <DialogTitle>Você tem certeza?</DialogTitle>
              <DialogContent>Esta ação é irreversível.</DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => {
                    setConfirmacaoDelecao(false);
                  }}
                >
                  <Typography textTransform={"none"}>Cancelar</Typography>
                </Button>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    deletarProduto();
                  }}
                >
                  <Typography textTransform={"none"}>Deletar</Typography>
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </main>
      </div>
      <Footer />
    </ThemeProvider>
  );
}
export default EditarItemCardapio;
