import React from "react";
import HeaderEstabelecimento from "../../cabecalho/estabelecimento/HeaderEstabelecimento";
import BarraLateral from "../../barra-lateral/BarraLateral";
import Footer from "../../Footer";
import axios from "axios";
import { useState, useEffect } from "react";
import { ambiente } from "../../../propriedades";
import { devIp } from "../../../propriedades";
import { prodIp } from "../../../propriedades";
import ItemCardapio from "../../item-cardapio/ItemCardapio";
import "./cardapio.css";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from "@mui/material";
import { propiedadesDoTema } from "../../../utils/tema";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

function Cardapio() {
  const [lista, setLista] = useState([]);
  const tema = createTheme(propiedadesDoTema);
  const [open, setOpen] = useState(false);
  const [dialogoAdicionarProdutoAberto, setDialogoAdicionarProdutoAberto] =
    useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [nomeProduto, setNomeProduto] = useState("");
  const [precoProduto, setPrecoProduto] = useState("");
  const [tempoDePreparo, setTempoDePreparo] = useState("");
  const [calorias, setCalorias] = useState("");
  const [disponivel, setDisponivel] = useState(true);
  const [contemGluten, setContemGluten] = useState(false);
  const [vegetariano, setVegetariano] = useState(false);
  const [categoria, setCategoria] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState("");
  const [vegano, setVegano] = useState(false);
  const [descricao, setDescricao] = useState("");
  const [campoImagemProdutoAdicionado, setCampoImagemProdutoAdicionado] =
    useState("");
  const [textoDialogoAdicionarProduto, setTextoDialogoAdicionarProduto] =
    useState(null);

  const definirImagemProdutoAdicionado = (event) => {
    try {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        let url = event.target.result;
        console.log(url);

        let image = document.createElement("img");
        image.src = url;

        image.onload = () => {
          const larguraDesejada = 200;
          const alturaDesejada = 200;

          let canvas = document.createElement("canvas");
          canvas.width = larguraDesejada;
          canvas.height = alturaDesejada;

          let context = canvas.getContext("2d");

          context.drawImage(image, 0, 0, larguraDesejada, alturaDesejada);
          canvas.toBlob(
            async (blob) => {
              const arrayBuffer = await blob.arrayBuffer();
              const byteArray = new Uint8Array(arrayBuffer);
              const base64Data = btoa(
                String.fromCharCode.apply(null, byteArray)
              );
              setCampoImagemProdutoAdicionado(base64Data);
            },
            "image/jpeg",
            0.9
          );
        };
      };
    } catch (error) {
      console.error("Erro ao processar a imagem:", error);
    }
  };
  let ip = "";
  if (ambiente === "dev") {
    ip = devIp;
  }

  if (ambiente === "prod") {
    ip = prodIp;
  }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };

  const navegar = useNavigate();

  const adicionarProduto = async () => {
    let textoDialogo = "";
    let primeiraInvalidez = true;
    let numeroCamposInvalidos = 0;
    if (campoImagemProdutoAdicionado == null) {
      numeroCamposInvalidos += 1;
      if (primeiraInvalidez) {
        primeiraInvalidez = false;
        textoDialogo = "Imagem do produto";
      } else {
        textoDialogo = textoDialogo + ", imagem";
      }
    }
    if (nomeProduto == "") {
      numeroCamposInvalidos += 1;
      if (primeiraInvalidez) {
        textoDialogo = "nome";
        primeiraInvalidez = false;
      } else {
        textoDialogo = textoDialogo + ", nome";
      }
    }
    if (precoProduto == "") {
      numeroCamposInvalidos += 1;
      if (primeiraInvalidez) {
        textoDialogo = "Preço";
        primeiraInvalidez = false;
      } else {
        textoDialogo = textoDialogo + ", preço";
      }
    }
    if (tempoDePreparo == "") {
      numeroCamposInvalidos += 1;
      if (primeiraInvalidez) {
        textoDialogo = "Tempo de preparo";
        primeiraInvalidez = false;
      } else {
        textoDialogo = textoDialogo + ", tempo de preparo";
      }
    }
    if (calorias == "") {
      if (primeiraInvalidez) {
        numeroCamposInvalidos += 1;
        textoDialogo = "Calorias";
        primeiraInvalidez = false;
      } else {
        textoDialogo = textoDialogo + ", calorias";
      }
    }
    if (descricao == "") {
      if (primeiraInvalidez) {
        numeroCamposInvalidos += 1;
        textoDialogo = "Descricao";
        primeiraInvalidez = false;
      } else {
        textoDialogo += ", descricao";
      }
    }

    if (textoDialogo != "") {
      if (numeroCamposInvalidos == 1) {
        textoDialogo += " invalido(a)";
      } else {
        textoDialogo += " invalidos(as)";
      }
      setDialogoAdicionarProdutoAberto(true);
      setTextoDialogoAdicionarProduto(textoDialogo);

      return;
    }

    const estabelecimentoId = localStorage.getItem("estabelecimentoId");
    try {
      const axiosInstance = axios.create({});
      const response = await axiosInstance.post(
        ip + "/produto/adicionar",
        {
          idDoEstabelecimento: estabelecimentoId,
          nome: nomeProduto,
          preco: precoProduto,
          imagem: campoImagemProdutoAdicionado,
          descricao: descricao,
          contemGluten: contemGluten,
          vegetariano: vegetariano,
          vegano: vegano,
          tempoDeEntrega: null,
          tempoDePreparo: tempoDePreparo,
          calorias: calorias,
          disponivel: disponivel,
          categoria: categoriaSelecionada,
        },
        {
          headers: {
            "Content-Type": "application/json",

            Authorization: localStorage.getItem("token"),
          }
        }
      );
      setOpen(false);
      setNomeProduto("");
      setPrecoProduto("");
      setTempoDePreparo("");
      setCalorias("");
      setDisponivel(true);
      setContemGluten(false);
      setVegetariano(false);
      setVegano(false);
      setDescricao("");
      handleClose();
      window.location.reload();
    } catch (error) {
      console.error(error);
      if (error.response.status === 402) {
        navegar("/portal/login");
      }
    }
  };
  const itemCardapioNotify = async () => {
    try {
      const response = await axios.post(
        ip + "/estabelecimento/telas/listarprodutos",
        null,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setLista(response.data.lista);
      setCategoriaSelecionada(response.data.categoriaSelecionada);
      setCategoria(response.data.categorias);
      
      
    } catch (error) {
      if (error.response.status === 402) {
        navegar("/portal/login");
      }
      console.log(error);
    }
  };
  useEffect(() => {
    itemCardapioNotify();
  }, []);

  return (
    <ThemeProvider theme={tema}>
      <HeaderEstabelecimento logo={true} />
      <div className="homeEstabelecimento">
        <BarraLateral />
        <Stack direction={"column"} alignItems={"center"} sx={{backgroundColor: "#e2ffe4"}}>
          <Box>
            <Box>
              <Typography
                marginTop={"20px"}
                marginBottom={"20px"}
                textAlign="center"
                color={tema.palette.secondary.dark}
                variant="h3"
              >
                Cardapio
              </Typography>
            </Box>
            <ButtonGroup fullWidth alignItems="center">
              <Button
                startIcon={<AddCircleOutlineIcon />}
                variant="contained"
                onClick={handleOpen}
                marginTop={"20px"}
              >
                <Typography textTransform={"none"}>
                  Adicionar Produto
                </Typography>
              </Button>
            </ButtonGroup>
            <Grid
              container
              width={"100%"}
              className="grade-cardapio"
              xs={"auto"}
              sx={{ gap: "15px", margin: "50px" }}
            >
              {lista.map((i) => {
                return <ItemCardapio props={i}></ItemCardapio>;
              })}
            </Grid>
          </Box>
        </Stack>
      </div>

      <Footer />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Adicionar Item</DialogTitle>
        <DialogContent>
          <Dialog open={dialogoAdicionarProdutoAberto}>
            <DialogContent>
              <Typography>{textoDialogoAdicionarProduto}</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setDialogoAdicionarProdutoAberto(false);
                }}
              >
                <Typography textTransform="none">Ok</Typography>
              </Button>
            </DialogActions>
          </Dialog>
          <TextField
            type="file"
            variant="outlined"
            fullWidth
            margin="normal"
            name="imagem"
            accept="image/*"
            onChange={(e) => {
              definirImagemProdutoAdicionado(e);
            }}
          />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              adicionarProduto();
            }}
          >
            <TextField
              label="Nome"
              variant="outlined"
              fullWidth
              margin="normal"
              name="nome"
              value={nomeProduto}
              onChange={(e) => {
                setNomeProduto(e.target.value);
              }}
            />
            <TextField
              select
              fullWidth
              label="Selecione a categoria"
              variant="outlined"
              margin="normal"
              value={categoriaSelecionada}
              onChange={(e) => {
                setCategoriaSelecionada(e.target.value);
              }}
            >
              {categoria.map((opcao) => (
                <MenuItem color="black" value={opcao.categoriaEnum}>
                  {opcao.nome}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Preço"
              variant="outlined"
              type="number"
              fullWidth
              margin="normal"
              name="preco"
              value={precoProduto}
              onChange={(e) => {
                setPrecoProduto(e.target.value);
              }}
            />
            <TextField
              label="Tempo de Preparo"
              variant="outlined"
              fullWidth
              placeholder="Em minutos"
              margin="normal"
              type="number"
              name="tempoDePreparo"
              value={tempoDePreparo}
              onChange={(e) => {
                setTempoDePreparo(e.target.value);
              }}
            />
            <TextField
              label="Calorias"
              variant="outlined"
              fullWidth
              margin="normal"
              name="calorias"
              value={calorias}
              onChange={(e) => {
                setCalorias(e.target.value);
              }}
            />

            <TextField
              label="Descrição"
              variant="outlined"
              fullWidth
              margin="normal"
              name="descricao"
              value={descricao}
              onChange={(e) => {
                setDescricao(e.target.value);
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="contemGluten"
                  checked={contemGluten}
                  onChange={(e) => {
                    setContemGluten(e.target.checked);
                  }}
                />
              }
              label="Contém Glúten"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="vegetariano"
                  checked={vegetariano}
                  onChange={(e) => {
                    setVegetariano(e.target.checked);
                  }}
                />
              }
              label="Vegetariano"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="vegano"
                  checked={vegano}
                  onChange={(e) => setVegano(e.target.checked)}
                />
              }
              label="Vegano"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="disponivel"
                  checked={disponivel}
                  onChange={(e) => {
                    setDisponivel(e.target.checked);
                  }}
                />
              }
              label="Disponível"
            />
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                <Typography textTransform={"none"}>Cancelar</Typography>
              </Button>
              <Button type="submit" variant="contained" color="primary">
                <Typography textTransform={"none"}>Enviar</Typography>
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Adicionar Item</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              adicionarProduto();
            }}
          >
            <TextField
              type="file"
              variant="outlined"
              fullWidth
              margin="normal"
              name="imagem"
              accept="image/*"
              onChange={(e) => {
                setCampoImagemProdutoAdicionado(e);
              }}
            />
            <TextField
              label="Nome"
              variant="outlined"
              fullWidth
              margin="normal"
              name="nome"
              value={nomeProduto}
              onChange={(e) => {
                setNomeProduto(e.target.value);
              }}
            />
            <TextField
              label="Preço"
              variant="outlined"
              type="number"
              fullWidth
              margin="normal"
              name="preco"
              value={precoProduto}
              onChange={(e) => {
                setPrecoProduto(e.target.value);
              }}
            />
            <TextField
              label="Tempo de Preparo"
              variant="outlined"
              fullWidth
              margin="normal"
              name="tempoDePreparo"
              value={tempoDePreparo}
              onChange={(e) => {
                setTempoDePreparo(e.target.value);
              }}
            />
            <TextField
              label="Calorias"
              variant="outlined"
              fullWidth
              margin="normal"
              name="calorias"
              value={calorias}
              onChange={(e) => {
                setCalorias(e.target.value);
              }}
            />
            <TextField
              label="Descrição"
              variant="outlined"
              fullWidth
              margin="normal"
              name="descricao"
              value={descricao}
              onChange={(e) => {
                setDescricao(e.target.value);
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="contemGluten"
                  checked={contemGluten}
                  onChange={(e) => {
                    setContemGluten(e.target.checked);
                  }}
                />
              }
              label="Contém Glúten"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="vegetariano"
                  checked={vegetariano}
                  onChange={(e) => {
                    setVegetariano(e.target.checked);
                  }}
                />
              }
              label="Vegetariano"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="vegano"
                  checked={vegano}
                  onChange={(e) => setVegano(e.target.checked)}
                />
              }
              label="Vegano"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="disponivel"
                  checked={disponivel}
                  onChange={(e) => {
                    setDisponivel(e.target.checked);
                  }}
                />
              }
              label="Disponível"
            />
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                <Typography textTransform={"none"}>Cancelar</Typography>
              </Button>
              <Button type="submit" variant="contained" color="primary">
                <Typography textTransform={"none"}>Enviar</Typography>
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

export default Cardapio;
