import './cadastro.css';
import CadastroForm from "../../../formulario-registro/CadastroForm.js";

function Cadastro() {
  return (
    <div className='telaCadastro'>
     <CadastroForm/>
    </div>
  );
}
export default Cadastro;
